import React from 'react';
import { renderTechnology} from 'route/Landing/sections/03_OurTech'
import Layout from "../components/Layout"
import 'styles/pages/_HowTo.scss';

export default function HowToUseIt({location}){
  return (
    <Layout location={location} t="el">
       <div className="page-how-to fade-in">
        {renderTechnology('el')}
      </div>
    </Layout>
  )
}